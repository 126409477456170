import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { removeSpaces, replaceSpaces, trackPageView } from '../../../shared/lib/utils';
import api from '../shared/lib/api';
import { IActiveVeileder, IActiveVeilederApiResponse, IVeilederStep } from '../shared/lib/types';
import VeilederSteg from './VeilederSteg';
import VeilederFooter from './footer/VeilederFooter';
import TilbakeTil from '../kunder/Tilbake';
import FeilmeldingSide from '../feilside/FeilmeldingSide';
import Spinner from '../spinner/Spinner';
import toast from 'react-hot-toast';
import { getQuestionValidationResult } from './veilederHelper';

type TParams = {
    veilederId: string;
    kundeId: string;
};

const Veileder = () => {
    let navigate = useNavigate();
    const { veilederId, kundeId } = useParams<TParams>();
    const [veileder, setVeileder] = useState<IActiveVeileder>();
    const [stepNumber, setStepNumber] = useState<number>(0);
    const [activeStep, setActiveStep] = useState<IVeilederStep>();
    const [kundeNavn, setKundeNavn] = useState<string>();
    const [egetforetak, setEgetforetak] = useState<boolean>(false);
    const [ksDebugger, setKsDebugger] = useState<boolean>(false);
    const [visFullBred, setFullBred] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [hasChanged, setHasChanged] = useState<boolean>(false);
    const [serverErrorCode, setServerErrorCode] = useState<number>(0);
    const isExternalApp = window.isExternalApp;

    const updateVeilederSteg = (steg: IVeilederStep) => {
        setVeileder({
            ...veileder,
            steps: veileder.steps.map((s) => {
                if (s.stepNumber === stepNumber) {
                    return steg;
                }
                return s;
            }),
        });
        setHasChanged(true);
    };
    const onPrevStepClick = () => {
        setLoading(true);
        api.forrigeStegPost(kundeId, veilederId, activeStep)
            .then((res) => {
                const activeStep = res?.veileder?.steps.find((step) => step.isActive);
                setStepNumber(activeStep.stepNumber);
                setActiveStep(activeStep);
                setVeileder(res.veileder);
                setHasChanged(false);
            })
            .catch((res) => setServerErrorCode(res.status))
            .finally(() => setLoading(false));
    };
    const onNextStepClick = () => {
        const result = getQuestionValidationResult(activeStep.questions);
        const hasQError = result.some((q) => q.validationErrorMessage);
        const hasTabularQError = result.some((q) => q.tabularValue.rows.some((r) => r.cells.some((c) => c.validationErrorMessage)));

        if (hasQError || hasTabularQError) {
            activeStep.questions = result;
            updateVeilederSteg(activeStep);
            return;
        }

        setLoading(true);
        api.nesteStegPost(kundeId, veilederId, activeStep)
            .then((res) => {
                if (res?.veileder?.showSummary) {
                    const url = `/kunder/${removeSpaces(kundeId)}/veileder/${removeSpaces(veilederId)}/oppsummering`;
                    navigate(url);
                    return;
                }
                const activeStep = res?.veileder?.steps.find((step) => step.isActive);
                setStepNumber(activeStep.stepNumber);
                setActiveStep(activeStep);
                setVeileder(res?.veileder);
                setLoading(false);
                setHasChanged(false);
            })
            .catch((res) => setServerErrorCode(res.status))
            .finally(() => setLoading(false));

        onTrackPageView(veileder.title);
    };
    const onGjenopptaStepClick = () => {
        setLoading(true);
        api.gjenopptaFraStegPost(kundeId, veilederId, activeStep)
            .then((res) => {
                const activeStep = res?.veileder?.steps.find((step) => step.isActive);
                setVeileder(res.veileder);
                setActiveStep(activeStep);
                setStepNumber(activeStep.stepNumber);
                setLoading(false);
            })
            .catch(() => toast.error('Kunne ikke gjenoppta steget.'))
            .finally(() => setLoading(false));
    };
    const onTilbakestillStepClick = () => {
        setLoading(true);
        api.tilbakestillStegPost(kundeId, veilederId, activeStep)
            .then((res) => {
                const activeStep = res?.veileder?.steps.find((step) => step.isActive);
                setVeileder(res.veileder);
                setActiveStep(activeStep);
                setStepNumber(activeStep.stepNumber);
                setLoading(false);
                setHasChanged(false);
            })
            .catch(() => toast.error('Kunne ikke tilbakestille steget.'))
            .finally(() => setLoading(false));
    };
    const onAvsluttStepClick = () => {
        if (veileder?.showSummary && veileder?.completed) {
            const url = `/kunder/${removeSpaces(kundeId)}/veileder/${removeSpaces(veilederId)}/oppsummering`;
            navigate(url);
            return;
        }
        const activeStep = veileder?.steps.find((step) => step.isActive);
        setActiveStep(activeStep);
        setStepNumber(activeStep.stepNumber);
    };
    const onSelectedStep = (stepNumber: number) => {
        const activeStep = veileder?.steps.find((step) => step.stepNumber === stepNumber);
        setActiveStep(activeStep);
        setStepNumber(stepNumber);
    };
    const onSaveClick = () => {
        setHasChanged(false);
        api.lagreStegPost(kundeId, veilederId, activeStep).then((res) => {
            if (isExternalApp) return window.close();

            return navigate(`/kunder/${removeSpaces(kundeId)}/kundeside`);
        });
    };
    const setActiveVeileder = (res: IActiveVeilederApiResponse) => {
        const veileder = res?.veileder;
        const lastStepNumber = veileder?.pathSteps[veileder?.pathSteps.length - 1].stepNumber;
        const activeStep = veileder?.steps?.find((step) => step.isActive);
        const lastStep = veileder?.steps.find((s) => s.stepNumber === lastStepNumber && !s.hiddenStep);
        const currentSteg = veileder?.completed ? lastStep : activeStep;

        setVeileder(res.veileder);
        setStepNumber(currentSteg.stepNumber);
        setActiveStep(currentSteg);
    };
    const onTrackPageView = (title: string) => {
        const locationPathNames = window.location.pathname.split('/');
        const veilederTitle = replaceSpaces(title, '-').toLowerCase();
        const subPath = stepNumber === 0 ? 'steg' : `steg/${stepNumber}`;
        const path = `/${locationPathNames[1]}/${locationPathNames[2]}/ID/veileder/${veilederTitle}/${subPath}`;

        trackPageView(path, veilederTitle);
    };
    const handleOnBeforeunload = (e: any) => {
        e.preventDefault();
        e.returnValue = '';
    };

    useEffect(() => {
        (() => {
            Promise.all([
                api.getActiveVeileder(veilederId, kundeId).then((res) => {
                    setActiveVeileder(res);
                    onTrackPageView(res.veileder.title);
                }),
                api.getInnloggetBruker().then((res) => {
                    if (res.regnskapsforetakId === kundeId) {
                        setKundeNavn(res.regnskapforetakNavn);
                        setEgetforetak(true);
                    } else {
                        api.getKunde(kundeId).then((res) => setKundeNavn(res.navn));
                    }
                    setKsDebugger(res.ksDebugger);
                }),
            ])
                .catch((error) => {
                    setServerErrorCode(error.status);
                })
                .finally(() => setLoading(false));

            document.title = 'KS Komplett - Veileder steg';
        })();
    }, [veilederId, kundeId]);

    useEffect(() => {
        if (!hasChanged) return;

        window.addEventListener('beforeunload', handleOnBeforeunload);
        return () => {
            window.removeEventListener('beforeunload', handleOnBeforeunload);
        };
    }, [hasChanged]);

    if (loading) {
        return <Spinner loading={loading} text="Lagrer... " />;
    }

    if (serverErrorCode) {
        return <FeilmeldingSide errorCode={serverErrorCode} url="/veiledere" />;
    }

    return (
        <div className="veileder">
            <div className="page-header page-header--dark page-header--sm">
                <div className="container">
                    <h1>{veileder?.title}</h1>
                </div>
            </div>
            <div className="page-header">
                <div className="container">
                    <span className={`icon ${egetforetak ? 'icon-eget-regnskapsforetak' : 'icon-kundenavn-stor'}`} />
                    <h2>{kundeNavn && kundeNavn}</h2>
                    <div className="flex-grow-1" />
                    <div className="hstack">
                        <button className="btn btn--primary" onClick={onSaveClick} disabled={!activeStep.isActive}>
                            Lagre og Lukk
                        </button>

                        {ksDebugger && !isExternalApp && (
                            <button className="btn btn--primary" onClick={onTilbakestillStepClick}>
                                Tilbakestill steget
                            </button>
                        )}
                    </div>
                </div>
            </div>
            {!isExternalApp && (
                <TilbakeTil
                    css={visFullBred ? 'container-full' : 'container'}
                    url={`/veileder/${removeSpaces(veilederId)}/velg-kunde`}
                    tekst="Tilbake til velg kunde"
                />
            )}
            <div className="page">
                {veileder && (
                    <div className={visFullBred ? 'container-full' : 'container'}>
                        <h2>
                            {activeStep?.stepHeader}
                            {ksDebugger && ` [${activeStep?.stepNumber}]`}
                        </h2>
                        <div className="row">
                            <div className="col-md-12 d-md-flex justify-content-end">
                                <button className="btn btn--secondary btn--sm" onClick={() => setFullBred(!visFullBred)}>
                                    {visFullBred ? 'Normal visning' : 'Bred visning'}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {veileder && (
                    <VeilederSteg ksDebugger={ksDebugger} fullBred={visFullBred} step={activeStep} updateVeilederSteg={updateVeilederSteg} />
                )}
            </div>
            {veileder && (
                <VeilederFooter
                    visFullBred={visFullBred}
                    isActive={activeStep?.isActive}
                    stepNumber={stepNumber}
                    pathSteps={veileder?.pathSteps}
                    steps={veileder?.steps}
                    onSelectedStep={onSelectedStep}
                    onNextStepClick={onNextStepClick}
                    onPrevStepClick={onPrevStepClick}
                    onGjenopptaStepClick={onGjenopptaStepClick}
                    onAvsluttStepClick={onAvsluttStepClick}
                />
            )}
        </div>
    );
};

export default Veileder;
